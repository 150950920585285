import { Point } from 'geojson';

export type PropertyValue = (string | number | boolean | null) & string;
export type TemporalPropertyValue = PropertyValue[];

export type TemporalProperty = {
  type: 'Property';
  values: TemporalPropertyValue[];
};

export type Property = {
  type: 'Property';
  value: PropertyValue;
  observedAt: string;
};

export type GeoPropertyValue = Point;
export type TemporalGeoPropertyValue = [Point, string];

export type TemporalGeoProperty = {
  type: 'GeoProperty';
  values: TemporalGeoPropertyValue[];
};

export type GeoProperty = {
  type: 'GeoProperty';
  value: GeoPropertyValue;
  observedAt: string;
};

export type Entity = {
  id: string;
  '@context': string;
};

export enum VesselProperty {
  MMSI = 'https://smartdatamodels.org/dataModel.MarineTransport/mmsi',
  VesselType = 'https://smartdatamodels.org/dataModel.MarineTransport/vesselType',
  VesselSubType = 'https://smartdatamodels.org/dataModel.MarineTransport/vesselSubType',
  DestinationPort = 'https://smartdatamodels.org/dataModel.MarineTransport/destinationPort',
  CourseOverGround = 'https://smartdatamodels.org/dataModel.MarineTransport/courseOverGround',
  Draught = 'https://smartdatamodels.org/dataModel.MarineTransport/draught',
  NavigationStatus = 'https://smartdatamodels.org/dataModel.MarineTransport/navigationStatus',
  DataProvider = 'https://smartdatamodels.org/dataProvider',
  PositionAccuracy = 'https://smartdatamodels.org/dataModel.MarineTransport/positionAccuracy',
  EstimatedTimeOfArrival = 'https://smartdatamodels.org/dataModel.MarineTransport/estimatedTimeOfArrival',
  SpecialManeuverIndicator = 'https://smartdatamodels.org/dataModel.MarineTransport/specialManeuverIndicator',
  SpeedOverGround = 'https://smartdatamodels.org/dataModel.MarineTransport/speedOverGround',
  IMO = 'https://smartdatamodels.org/dataModel.MarineTransport/imo',
  Heading = 'https://smartdatamodels.org/dataModel.MarineTransport/heading',
  Name = 'name',
}

export const VESSEL_NAV_STATUSES = [
  'Under way using engine',
  'At anchor',
  'Not under command',
  'Restricted maneuverability',
  'Constrained by her draught',
  'Moored',
  'Aground',
  'Engaged in fishing',
  'Under way sailing',
];

export const VESSEL_TYPES = {
  0: 'Not available',
  1: 'Reserved',
  2: 'Wing in Ground',
  3: 'Special category',
  4: 'High speed craft',
  5: 'Special category',
  6: 'Passenger',
  7: 'Cargo',
  8: 'Tanker',
  9: 'Other',
};

export const VESSEL_SUBTYPES = {
  0: 'Not available',
  10: 'Wing in ground (WIG)',
  21: 'Wing in ground (WIG) - Hazardous category A',
  22: 'Wing in ground (WIG) - Hazardous category B',
  23: 'Wing in ground (WIG) - Hazardous category C',
  24: 'Wing in ground (WIG) - Hazardous category D',
  30: 'Fishing',
  31: 'Towing',
  32: 'Towing: length exceeds 200m or breadth exceeds 25m',
  33: 'Dredging or underwater ops',
  34: 'Diving ops',
  35: 'Military ops',
  36: 'Sailing',
  37: 'Pleasure craft',
  40: 'High speed craft, all types',
  41: 'High speed craft, Hazardous category A',
  42: 'High speed craft, Hazardous category B',
  43: 'High speed craft, Hazardous category C',
  44: 'High speed craft, Hazardous category D',
  49: 'High speed craft, No additional information',
  50: 'Pilot Vessel',
  51: 'Search and Rescue vessel',
  52: 'Tug',
  53: 'Port Tender',
  54: 'Anti-pollution equipment',
  55: 'Law Enforcement',
  58: 'Medical Transport',
  59: 'Noncombatant ship according to RR Resolution No. 18',
  60: 'Passenger, all ships of this type',
  61: 'Passenger, Hazardous category A',
  62: 'Passenger, Hazardous category B',
  63: 'Passenger, Hazardous category C',
  64: 'Passenger, Hazardous category D',
  69: 'Passenger, No additional information',
  70: 'Cargo, all ships of this type',
  71: 'Cargo, Hazardous category A',
  72: 'Cargo, Hazardous category B',
  73: 'Cargo, Hazardous category C',
  74: 'Cargo, Hazardous category D',
  79: 'Cargo, No additional information',
  80: 'Tanker, all ships of this type',
  81: 'Tanker, Hazardous category A',
  82: 'Tanker, Hazardous category B',
  83: 'Tanker, Hazardous category C',
  84: 'Tanker, Hazardous category D',
  89: 'Tanker, No additional information',
  90: 'Other type, all ships of this type',
  91: 'Other type, Hazardous category A',
  92: 'Other type, Hazardous category B',
  93: 'Other type, Hazardous category C',
  94: 'Other type, Hazardous category D',
  99: 'Other type, No additional information',
};

export const POSITION_ACCURACIES = {
  0: 'Low (> 10m)',
  1: 'High (< 10m)',
};

export const SPECIAL_MANEUVER_INDICATORS = {
  0: 'Not available',
  1: 'Not engaged in special maneuver',
  2: 'Engaged in special maneuver',
};

export type TemporalVessel = Entity & {
  [key in VesselProperty]: TemporalProperty;
} & {
  type: 'Vessel';
  location: TemporalGeoProperty;
};

export type Vessel = Entity & { [key in VesselProperty]: Property } & {
  type: 'Vessel';
  location: GeoProperty;
};

export type LoadingData = {
  isLoading: boolean;
  updatedAt?: string;
};

export function convertTemporalVessels(
  temporalVessels: TemporalVessel[],
): Vessel[] {
  const vessels: Vessel[] = [];
  temporalVessels.forEach((temporalVessel: TemporalVessel) => {
    const newVessel: Partial<Vessel> = {
      '@context': '',
      id: temporalVessel.id,
      type: temporalVessel.type,
      name: {
        type: 'Property',
        value:
          (temporalVessel.name && temporalVessel.name.values[0][0]) ||
          'UNKNOWN',
        observedAt:
          (temporalVessel.name && temporalVessel.name.values[0][1]) || null,
      } as Property,
      location: {
        type: 'GeoProperty',
        value: temporalVessel.location.values[0][0],
        observedAt: temporalVessel.location.values[0][1],
      } as GeoProperty,
      'https://smartdatamodels.org/dataModel.MarineTransport/mmsi': {
        type: 'Property',
        value:
          temporalVessel[
            'https://smartdatamodels.org/dataModel.MarineTransport/mmsi'
          ].values[0][0],
        observedAt:
          temporalVessel[
            'https://smartdatamodels.org/dataModel.MarineTransport/mmsi'
          ].values[0][1],
      },
    };

    Object.values(VesselProperty).forEach((prop) => {
      if (prop in temporalVessel) {
        newVessel[prop] = {
          type: 'Property',
          value: temporalVessel[prop].values[0][0],
          observedAt: temporalVessel[prop].values[0][1],
        };
      }
    });

    vessels.push(newVessel as Vessel);
  });

  return vessels;
}
