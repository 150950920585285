export type CustomProcessEnv = {
  REACT_APP_INITIAL_MAP_CONFIG_JSON: string;
  REACT_APP_ENTITIES_PROXY_ENDPOINT: string;
  REACT_APP_USER_DISPLAY_NAME: string;
  REACT_APP_LOGO_URL: string;
  REACT_APP_THEME: string;
};

export type ENVIRONMENT_VARIABLE = keyof CustomProcessEnv;

export function getStringVar(name: ENVIRONMENT_VARIABLE) {
  return ((process.env as CustomProcessEnv)[name] || '').trim();
}

export function getJSONVar(name: ENVIRONMENT_VARIABLE) {
  let json = getStringVar(name);
  if (!json) {
    return {};
  }
  // Remove quotes if present (e.g. in Docker or k8s)
  if (json.startsWith("'") && json.endsWith("'")) {
    json = json.slice(1, -1);
  }
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(`Error parsing ${name} as JSON: ${e}`);
  }
}

export function getURLVar(name: ENVIRONMENT_VARIABLE): string {
  return getStringVar(name).replace(/\/+$/g, '');
}

export const ENTITIES_PROXY_ENDPOINT = getURLVar(
  'REACT_APP_ENTITIES_PROXY_ENDPOINT',
);
export const USER_DISPLAY_NAME = getStringVar('REACT_APP_USER_DISPLAY_NAME');
export const LOGO_URL = getURLVar('REACT_APP_LOGO_URL');
