import React, { useCallback } from 'react';
import ReactMapGL, { LngLatLike, MapRef } from 'react-map-gl';

import { MapVesselCollection } from '@/components/map/controls/MapVesselCollection';
import { MapCorridorsOfInterestLayer } from '@/components/map/layers/MapCorridorsOfInterest';
import { INITIAL_MAP_CONFIG } from '@/constants';
import { LoadingData, TemporalVessel, Vessel } from '@/store/vessels';
import { MapDateTimeControl } from './controls/MapDateTimeControl';
import { MapLoadingControl } from './controls/MapLoadingControl';
import { MapVesselControl } from './controls/MapVesselControl';
import { MapPointsOfInterestLayer } from './layers/MapPointsOfInterestLayer';
import { MapTrackLayer } from './layers/MapTrackLayer';
import { MapMarker } from './util/MapMarker';
import { getBoundsForVessels, getBoundsForVesselTrack } from '@/utils/map';
import { LngLatBoundsLike } from 'mapbox-gl';

type Props = {
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
  vessels: Vessel[];
  activeVessel: Vessel | null;
  activeVesselTrack: TemporalVessel | null;
  loadingData?: LoadingData;
  historicalMode?: boolean;
};

const FIT_BOUNDS_OPTIONS = {
  padding: 50,
};

export const Map = ({
  vessels,
  startTime,
  endTime,
  activeVessel,
  activeVesselTrack,
  loadingData,
  historicalMode,
}: Props) => {
  const mapRef = useCallback(
    (map: MapRef) => {
      if (map) {
        const bounds: LngLatBoundsLike | null =
          activeVessel && activeVesselTrack
            ? getBoundsForVesselTrack(activeVesselTrack)
            : !activeVessel && vessels.length > 0
            ? getBoundsForVessels(vessels)
            : null;

        if (bounds) {
          map.fitBounds(bounds, FIT_BOUNDS_OPTIONS);
        } else if (activeVessel) {
          // focus on the active vessel instead
          map.flyTo({
            center: activeVessel.location.value.coordinates as LngLatLike,
            zoom: 10,
          });
        }
      }
    },
    [vessels, activeVesselTrack],
  );

  return (
    <ReactMapGL
      dragPan
      initialViewState={INITIAL_MAP_CONFIG}
      mapStyle="mapbox://styles/mapbox/light-v10"
      maxZoom={15}
      ref={mapRef}
      style={{
        width: '100vw',
        height: 'calc(100vh - 64px)',
      }}
    >
      <MapLoadingControl
        endTime={endTime}
        historicalMode={historicalMode}
        loadingData={loadingData}
        startTime={startTime}
      />
      {!activeVessel && <MapVesselCollection />}
      <MapCorridorsOfInterestLayer />
      <MapPointsOfInterestLayer />
      {activeVessel && (
        <>
          <MapVesselControl
            endTime={endTime}
            startTime={startTime}
            vessel={activeVessel}
          />
          <MapMarker key={activeVessel.id} vessel={activeVessel} />
          {activeVesselTrack && (
            <MapTrackLayer
              vessel={activeVessel}
              vesselTrack={activeVesselTrack}
            />
          )}
        </>
      )}
      {!activeVessel &&
        vessels &&
        vessels.map((vessel) => <MapMarker key={vessel.id} vessel={vessel} />)}
      {historicalMode && (
        <MapDateTimeControl endTime={endTime} startTime={startTime} />
      )}
    </ReactMapGL>
  );
};
